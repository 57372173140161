import EmailInput from '../../../components/ui/email-input/email-input';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../providers/auth-provider';
import { UserService } from '../../../services/user-service';
import { iTeam, iUser } from '@shared/shared-utils/models';
import { DBUserRoles, DiscordWebhooks } from '@shared/shared-utils';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import TextInput from '../../../components/ui/text-input/text-input';
import ImagePicker from '../../../components/ui/image-picker/image-picker';
import {
  EmailAuthProvider,
  linkWithCredential,
  updateProfile,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import NewPasswordInput from '../../../components/ui/password-input/confirm-password-input';
import Loading from '../../../components/ui/loading-animation';
import { DiscordService } from '../../../services/discord-service';
import UserConsentCheckbox from '../../../components/widgets/consent/user-consent';
import AuthWrapper from '../../../components/ui/auth-wrapper/auth-wrapper';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Autocomplete,
} from '@mui/material';
import { MuiTelInput, MuiTelInputInfo, matchIsValidTel } from 'mui-tel-input';
import { TeamsContext } from '../../../providers/teams-provider';
import logo from '../../../assets/images/centered-logo.png';
import { teamNames } from '../../../utils/team-names';

const Signup = () => {
  const userService = new UserService();
  const {
    isLoggedIn,
    currentUser,
    signupWithEmailAndPasssword,
    signInWithGoogle,
    updateLoginStatus,
    logOut,
  } = useContext(AuthContext);

  const navigate = useNavigate();
  const _discordService = new DiscordService();
  const { state }: Location = useLocation();
  const { mEmail }: any = state;
  const [allTeams, setAllTeams] = useState<string[]>([]);
  const [email, setEmail] = useState<string | undefined>(mEmail);
  const [profilePic, setProfilePic] = useState<File | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [fullName, setFullName] = useState<string>('');
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [userInputedSource, setUserInputedSource] = useState<string>('');
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [inValidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [showCharacterLimitError, setShowCharacterLimitError] = useState(false);
  const [showCharacterLimitHelperText, setShowCharacterLimitHelperText] =
    useState(false);
  const [favoriteTeam, setFavoriteTeam] = useState<string>('');

  const addUser = async () => {
    if (email && password) {
      let credential;
      let imageLink = '';
      if (!currentUser) {
        credential = await signupWithEmailAndPasssword(email, password);
      } else {
        try {
          const emailCredential = EmailAuthProvider.credential(email, password);
          credential = await linkWithCredential(currentUser, emailCredential);
          if (currentUser.photoURL) {
            imageLink = currentUser.photoURL;
          }
        } catch (error) {
          const fError = error as FirebaseError;
          if (fError.code === 'auth/requires-recent-login') {
            await signInWithGoogle();
            addUser();
          }
        }
      }

      if (credential) {
        if (profilePic) {
          imageLink = await userService.uploadProfilePic(
            credential.user,
            profilePic,
          );
        }

        await userService.addUserProfile(
          {
            fullName,
            email,
            profileImage: imageLink,
            isActive: true,
            role: DBUserRoles.leagueMember,
            termsAgreed: termsAgreed,
            discordReminderStatus: true,
            createdDate: new Date(),
            phoneNumber: phoneNumber,
          } as iUser,
          selectedSource,
          userInputedSource,
          favoriteTeam,
        );

        _discordService.postDiscordMessage(
          `New user added: ${fullName} from ${selectedSource} - ${userInputedSource} - ${favoriteTeam}`,
          DiscordWebhooks.activityChannel,
        );

        if (email) {
          userService.sendInviteMemberEmail(email);
        }

        // Why do we do this?
        const userUpdated = await updateProfile(credential.user, {
          displayName: fullName,
          photoURL: imageLink,
        });

        if (userUpdated !== null) await updateLoginStatus(credential.user);
      }
    }

    // Send them to the home page and look for the redirect
    navigateToCorrectPage(true);
    setSignUpLoading(false);
  };

  const navigateToCorrectPage = async (accountCreated?: boolean) => {
    if ((isLoggedIn && !signUpLoading) || accountCreated) {
      const redirectUrl = localStorage.getItem('rTo');
      if (redirectUrl) {
        navigate(redirectUrl);
        return;
      }
      navigate('/home');
    }
    if (mEmail) setEmail(mEmail);
  };

  const handleNewPassword = (passwordMatch: boolean, password?: string) => {
    if (passwordMatch) {
      setPassword(password);
    } else {
      setPassword(undefined);
    }
  };

  const handlePhoneNumberChange = (value: string, info: MuiTelInputInfo) => {
    const validNumber = matchIsValidTel(
      value,
      info.countryCode ? info.countryCode : undefined,
    );

    setPhoneNumber(value);
    if (value === '' || info.nationalNumber === '' || validNumber) {
      setInvalidPhoneNumber(false);
    } else {
      setInvalidPhoneNumber(true);
    }
  };

  const loadTeams = async () => {
    setAllTeams(teamNames ? teamNames : []);
  };

  useEffect(() => {
    loadTeams();
  }, []);

  useEffect(() => {
    // Make sure we aren't in the middle of account creation
    if (!signUpLoading || isLoggedIn) {
      navigateToCorrectPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, currentUser, mEmail]);

  return signUpLoading ? (
    <Loading></Loading>
  ) : (
    <AuthWrapper hideLogo={true}>
      <div className="flex ml-4 mb-10">
        <img src={logo} alt="Logo" className="mx-auto" />
      </div>
      <h2>SIGN UP</h2>
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          if (
            email &&
            password &&
            fullName &&
            termsAgreed &&
            !inValidPhoneNumber
          ) {
            setSignUpLoading(true);
            addUser();
          }
        }}
      >
        <div>
          {/* <ImagePicker
            label="Add profile picture"
            imageLink={currentUser?.photoURL}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files?.length) {
                setProfilePic(e.target.files[0]);
              }
            }}
          /> */}
          <div className="mt-4 mb-4">
            <TextInput
              value={fullName}
              label="Full Name*"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length < 23) {
                  setFullName(event.target.value);
                  setShowCharacterLimitError(false);
                } else {
                  setShowCharacterLimitError(true);
                  setShowCharacterLimitHelperText(true);
                }
              }}
              error={showCharacterLimitError}
              helperText={
                showCharacterLimitHelperText
                  ? `Name must be 22 characters or less. [${fullName?.length}/22]`
                  : undefined
              }
            />
          </div>
          <EmailInput
            label="Email address"
            value={email}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
            disabled={true}
            sxProps={{
              marginY: '0.5rem',
            }}
          />
          <div className="mt-2">
            <MuiTelInput
              defaultCountry="US"
              value={phoneNumber}
              onChange={(value, info) => handlePhoneNumberChange(value, info)}
              label="Phone Number"
              forceCallingCode={true}
              fullWidth
              sx={{
                marginY: '0.5rem',
              }}
            />
          </div>
          <div className="mb-4">
            <NewPasswordInput doPasswordsMatch={handleNewPassword} />
          </div>
          {/* Favorite Team Dropdown */}
          <Autocomplete
            options={allTeams.map((team) => team)}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Favorite College Football Team"
                fullWidth
              />
            )}
            value={favoriteTeam}
            onChange={(event: any, newValue: string | null) => {
              setFavoriteTeam(newValue || '');
            }}
            sx={{ marginY: '0.5rem' }}
          />
          <div className="py-2 mb-4 mt-2">
            <FormControl fullWidth variant="standard">
              <InputLabel id="source-select-label">
                How did you find us?
              </InputLabel>
              <Select
                labelId="source-select-label"
                label="How did you find us?"
                id="source-select"
                value={selectedSource}
                onChange={(event: SelectChangeEvent<string>) => {
                  setSelectedSource(event.target.value);
                }}
              >
                <MenuItem value={'facebook'}>Facebook</MenuItem>
                <MenuItem value={'x'}>X/Twitter</MenuItem>
                <MenuItem value={'youtube'}>Youtube</MenuItem>
                <MenuItem value={'reddit'}>Reddit</MenuItem>
                <MenuItem value={'google'}>Google Search</MenuItem>
                <MenuItem value={'blog'}>Blog</MenuItem>
                <MenuItem value={'appStore'}>App Store</MenuItem>
                <MenuItem value={'instagram'}>Instagram</MenuItem>
                <MenuItem value={'other'}>Other</MenuItem>
              </Select>
              {selectedSource === 'other' && (
                <div className="pt-2 mt-2">
                  <TextInput
                    label="Where did you find us?"
                    value={userInputedSource}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setUserInputedSource(event.target.value);
                    }}
                  />
                </div>
              )}
            </FormControl>
          </div>
          <UserConsentCheckbox
            setTermsAgreed={setTermsAgreed}
            termsAgreed={termsAgreed}
          />
        </div>
        <div className="flex justify-between mt-4">
          <Button
            color="secondary"
            onClick={() => {
              logOut();
              navigate('/');
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="outlined"
            disabled={
              !email ||
              !password ||
              !fullName ||
              !termsAgreed ||
              inValidPhoneNumber
            }
            onClick={() => {
              setSignUpLoading(true);
              addUser();
            }}
          >
            FINISH
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default Signup;
