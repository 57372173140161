import Button from '@mui/material/Button/Button';

type TeamResearchProps = {
  buttonClick: () => void;
};

const TeamResearch = (props: TeamResearchProps) => {
  return (
    <div className="flex flex-col flex-1 mt-4">
      <div>
        <div className="my-2 text-base text-center">
          Research the teams before you set your lineup!
        </div>
        <div className="my-2 text-sm flex flex-col px-8 mt-4">
          <span className="italic">
            Just remember, football is 80 percent mental and 40 percent
            physical.
          </span>
          <span className="font-bold self-end mt-1">
            - Steve Emtman, The Little Giants
          </span>
        </div>
      </div>
      <div className="text-center mt-12">
        <Button
          children="Research Teams"
          variant="contained"
          onClick={() => props.buttonClick()}
          data-e2e="research_teams_btn"
        />
      </div>
    </div>
  );
};

export default TeamResearch;
