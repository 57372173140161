import {
  iGameResults,
  iSmallGame,
  iTeam,
  iTeamDetailsTableData,
} from '@shared/shared-utils/models';
import TeamDetailsTable from './team-details-table';
import { MenuItem, Select } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { TeamsContext } from '../../../providers/teams-provider';
import Loading from '../../ui/loading-animation';
import { LeagueContext } from '../../../providers/league-provider';
import TeamCategoryRanking from './team-category-ranking';

type TeamDetailsType = {
  team: iTeam;
  onError: () => void;
};

interface iOpponents {
  teamName: string;
  teamId: number;
  week: number;
  gameDate: string;
}

const TeamDetails = (props: TeamDetailsType) => {
  const { appConfig, currentLeague } = useContext(LeagueContext);
  const { getSeasonResultsByTeam, getTeamRankingsPerCategory } =
    useContext(TeamsContext);

  // There should be data for this season after week 1.
  // If > week 1, make current season the default to display in the table.
  // Otherwise, make last season the default
  const [selectedSeason, setSelectedSeason] = useState<number>(
    appConfig.currentWeek > 1
      ? appConfig.currentSeason
      : appConfig.currentSeason - 1,
  );
  const [pageLoading, setPageLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [teamResults, setTeamResults] = useState<{
    [season: number]: iGameResults[];
  }>();
  const [teamDetailsTableData, setTeamDetailsTableData] = useState<{
    [season: number]: iTeamDetailsTableData[];
  }>();
  const [teamRankingsPerCategory, setTeamRankingsPerCategory] = useState<{
    teamId: string;
    passing: {
      totalScore: number;
      rank: number;
      average: number;
    };
    rushing: {
      totalScore: number;
      rank: number;
      average: number;
    };
    defensive: {
      totalScore: number;
      rank: number;
      average: number;
    };
  }>();

  const handleSetResultsState = (
    season: number,
    newResults: iGameResults[],
    existingResults?: { [season: number]: iGameResults[] },
  ) => {
    if (existingResults) {
      setTeamResults({ ...existingResults, [season]: newResults });
    } else {
      setTeamResults({ [season]: newResults });
    }
  };

  /**
   * This will save all the data retrieved so that it doesn't have to be retrieved each time selectedSeason is changed.
   */
  const handleSetTableDataState = (
    season: number,
    newData: iTeamDetailsTableData[],
    existingData?: { [season: number]: iTeamDetailsTableData[] },
  ) => {
    if (existingData) {
      setTeamDetailsTableData({ ...existingData, [season]: newData });
    } else {
      setTeamDetailsTableData({ [season]: newData });
    }
  };

  // Build the opponent list for column 1
  const getTeamOpponents = () => {
    const opponentMap: Map<number, iOpponents> = new Map();

    const schedule = props.team[
      `${selectedSeason}-schedule` as keyof typeof props.team
    ] as {
      [key: number]: iSmallGame;
    };

    Object.values(schedule).forEach((game) => {
      if (game.away_id === props.team.id) {
        opponentMap.set(game.week, {
          teamId: game.home_id,
          teamName: game.home_team,
          week: game.week,
          gameDate: game.start_date,
        });
      } else {
        opponentMap.set(game.week, {
          teamId: game.away_id,
          teamName: game.away_team,
          week: game.week,
          gameDate: game.start_date,
        });
      }
    });
    return opponentMap;
  };

  //Get and build all the data upon initial render
  const initTeamDetails = async (abortOnFailure?: boolean) => {
    setPageLoading(true);
    try {
      // Pull the data for the team category ranking cards
      if (currentLeague) {
        const teamIdString = props.team.id + '';
        //TODO: how to handle prior to week 1
        const teamRankings = await getTeamRankingsPerCategory(
          appConfig.currentSeason,
          props.team,
          currentLeague,
        );
        setTeamRankingsPerCategory(teamRankings);
      }

      const opponents = getTeamOpponents();
      const results = await getSeasonResultsByTeam(selectedSeason, props.team);
      const resultsMap = new Map();
      results?.forEach((result) => {
        if (result[props.team.id] && result[props.team.id].week) {
          resultsMap.set(
            result[props.team.id].week,
            result[props.team.id].stats,
          );
        }
      });

      const data: iTeamDetailsTableData[] = [];

      // Add schedule
      opponents.forEach((opponent) => {
        data.push({
          week: opponent.week,
          opponentName: opponent.teamName,
          gameDate: opponent.gameDate,
        } as iTeamDetailsTableData);
      });

      // Add results to the schedule
      if (results) {
        data.forEach((oppObj) => {
          oppObj.stats = resultsMap.get(oppObj.week);
        });

        // Sort data in order that the games were played
        data.sort((a, b) => a.week - b.week);

        handleSetTableDataState(selectedSeason, data, teamDetailsTableData);
        setTableLoading(false);
        setPageLoading(false);

        handleSetResultsState(selectedSeason, results, teamResults);
      } else {
        // If results fails, retry once then abort and close modal
        console.log('Unable to get results.');
        if (abortOnFailure) {
          console.log('Aborting team details.');
          props.onError();
        } else {
          console.log('retrying once more...');
          initTeamDetails(true);
        }
      }
    } catch (error) {
      //Show error toast and close modal
      console.log('Error trying get game results.', error);
      props.onError();
    }
  };

  //Checks for state data before initializing
  useEffect(() => {
    if (!teamResults || !teamResults[selectedSeason]) {
      initTeamDetails();
    }
  }, [selectedSeason]);

  return pageLoading ? (
    <Loading />
  ) : (
    <div className="m-2 overflow-y-scroll max-h-[90vh] bg-db-header-background-color px-2">
      <div
        style={{
          backgroundImage: `url(${props.team.logos[0].replace(
            /http:/g,
            'https:',
          )})`,
        }}
        className="bg-no-repeat bg-cover bg-primary my-4 rounded-full h-16 w-16 min-h-[2rem] team-image cursor-pointer"
        title={props.team.school}
      />
      <div className="mt-1 mb-4 text-2xl">
        {props.team.school} {props.team.mascot}
      </div>
      {/* TODO: should already be set. Try to remove the check */}
      {appConfig.currentWeek > 1 && teamRankingsPerCategory && (
        <TeamCategoryRanking teamRankings={teamRankingsPerCategory} />
      )}
      <div>
        <div className="flex flex-row justify-between items-center">
          <div className="font-bold uppercase">Performances</div>
          <Select
            value={selectedSeason}
            onChange={(e) => setSelectedSeason(+e.target.value)}
          >
            {/* TODO: get season numbers programatically */}
            {/* TODO: make sure seasons stay in descending order */}
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
          </Select>
        </div>
        <div>
          {tableLoading || !teamDetailsTableData ? (
            <Loading />
          ) : teamDetailsTableData[selectedSeason] &&
            Object.values(teamDetailsTableData[selectedSeason]).length > 0 ? (
            <TeamDetailsTable
              tableData={teamDetailsTableData[selectedSeason]}
            />
          ) : (
            <>We don't have the data for the {selectedSeason} season yet!</>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
