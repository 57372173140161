import React from 'react';
import ModalWrapper from '../modal-wrapper/modal-wrapper';
import Image from '../../widgets/image';
import { iMatchup, iUser, iWeeklyReport } from '@shared/shared-utils/models';
import ScoreCard from '../../ui/score-card';
import PlayerCard from '../../ui/player-card';

type WeeklyReportProp = {
  weeklyReport: iWeeklyReport;
  members: iUser[];
  onCloseClick: () => void;
  matchupResults: iMatchup;
  leagueName: string;
};

type creatorHighlightMap = Record<
  number,
  { logoUrl: string; linkUrl: string; name: string } | undefined
>;

const WeeklyReport: React.FC<WeeklyReportProp> = ({
  weeklyReport,
  members,
  onCloseClick,
  matchupResults,
  leagueName,
}) => {
  const creatorHighlightMap: creatorHighlightMap = {
    1: {
      logoUrl:
        'https://yt3.googleusercontent.com/4A3RBdnTJ1sVNCYlYh-mHqcIAm6hnzf_VW3yxTSvvqgLfE2Gwefl-8xQI1MZuIl-P1KUitXg0g=s160-c-k-c0x00ffffff-no-rj',
      linkUrl: 'https://www.youtube.com/@collegefootballwithsam',
      name: 'College Football with Sam',
    },
    2: {
      logoUrl:
        'https://yt3.googleusercontent.com/u52LSXjeKZLJgJ-NZn-wbbyvXvedfo5vXE87P99jcPk2-36YeOu7QMFIqHN8lfN5o-CAtcPJ=s160-c-k-c0x00ffffff-no-rj',
      linkUrl: 'https://www.youtube.com/@AlwaysTalkinBall',
      name: 'Always Talkin Ball',
    },
    3: {
      logoUrl:
        'https://ugc.production.linktr.ee/d477d0f8-f845-4257-b661-5d850ca3a2ed_1000003535.jpeg?io=true&size=avatar-v3_0',
      linkUrl: 'https://linktr.ee/TheVFLshow',
      name: 'The VFL Show',
    },
    4: {
      logoUrl:
        'https://yt3.googleusercontent.com/yoIE78-saMqbnCIl-PssGv9dT2xboi6PjuoePH7DowiUgj9t-YwN6er52t-bO4b73__22Oe3gA=s160-c-k-c0x00ffffff-no-rj',
      linkUrl: 'https://thecollegefantasyfootballsite.com/',
      name: 'The College Fantasy Football Site',
    },
  };
  const memberMap = members.reduce(
    (acc, member) => {
      acc[member.id] = member;
      return acc;
    },
    {} as Record<string, (typeof members)[0]>,
  );

  const mostPointsScoredUser = memberMap[weeklyReport.mostPointsScored.userId]!;
  const fewestPointsScoredUser =
    memberMap[weeklyReport.fewestPointsScored.userId]!;
  const mostPointsInLossUser = memberMap[weeklyReport.mostPointsInLoss.userId]!;
  const fewestPointsInWinUser =
    memberMap[weeklyReport.fewestPointsInWin.userId]!;

  const memberA = memberMap[matchupResults?.memberA!]!;
  const memberB = memberMap[matchupResults?.memberB!]!;

  const creatorHighlight = creatorHighlightMap[weeklyReport.week];

  return (
    <ModalWrapper
      title={`Week ${weeklyReport.week}`}
      backEnabled={false}
      closeOnTapOutside={true}
      onCloseClicked={onCloseClick}
    >
      <div
        className="relative flex flex-col items-center p-2 h-full overflow-y-auto bg-black text-white pb-[15vh]"
        data-e2e="weekly-report-container"
      >
        <div className="flex justify-center">
          <Image
            imageUrl="/assets/images/icon.png"
            title="Squad Blitz Logo"
            size={16}
            specialImageClass="rounded-none bg-transparent"
            clickable={false}
          />
        </div>
        <div className="text-lg text-left w-full font-bold">
          Great week Blitzers!
        </div>
        <div className="text-md text-left w-full">
          Look below to see how you did!
        </div>

        <div className="flex justify-center w-full mb-4">
          <div className="w-full">
            <ScoreCard
              userA={memberA}
              userB={memberB}
              homeScore={matchupResults.memberAPoints}
              awayScore={matchupResults.memberBPoints}
              leagueName={leagueName}
              leagueWeek={matchupResults.round + ''}
              ringColor="ring-transparent"
              backgroundColor="#f0f0f0"
              titleBackgroundColor="#d9d9d9"
            />
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-1/2 h-full p-1">
            <PlayerCard
              category="Most Points Scored"
              name={mostPointsScoredUser?.fullName}
              score={weeklyReport.mostPointsScored.value}
              imageUrl={mostPointsScoredUser?.profileImage}
              ringColor="ring-transparent"
              backgroundColor="#d9e8dd"
              titleBackgroundColor="#81c88f"
            />
            <PlayerCard
              category="Fewest Points Scored"
              name={fewestPointsScoredUser?.fullName}
              score={weeklyReport.fewestPointsScored.value}
              imageUrl={fewestPointsScoredUser?.profileImage}
              ringColor="ring-transparent"
              backgroundColor="#fde8e8"
              titleBackgroundColor="#f8b4b4"
            />
          </div>
          <div className="flex flex-col w-1/2 h-full p-1">
            <PlayerCard
              category="Most Points in a loss"
              name={mostPointsInLossUser?.fullName}
              score={weeklyReport.mostPointsInLoss.value}
              imageUrl={mostPointsInLossUser?.profileImage}
              ringColor="ring-transparent"
              backgroundColor="#fde8e8"
              titleBackgroundColor="#f8b4b4"
            />

            <PlayerCard
              category="Fewest Points in a win"
              name={fewestPointsInWinUser?.fullName}
              score={weeklyReport.fewestPointsInWin.value}
              imageUrl={
                members.find(
                  (member) =>
                    member.id === weeklyReport.fewestPointsInWin.userId,
                )?.profileImage
              }
              ringColor="ring-transparent"
              backgroundColor="#d9e8dd"
              titleBackgroundColor="#81c88f"
            />
          </div>
        </div>
      </div>
      {creatorHighlight && (
        <div className="fixed bottom-0 left-0 w-full h-[15vh] flex flex-col items-center justify-center bg-black text-white z-50">
          <div className="flex items-center">
            <a
              href={creatorHighlight.linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-4"
            >
              <Image
                imageUrl={creatorHighlight.logoUrl}
                title="Creator Highlight"
                size={16}
                specialImageClass="rounded-none border-none bg-transparent"
                clickable={true}
              />
              <span className="text-lg font-bold">{creatorHighlight.name}</span>
            </a>
          </div>
          <div className="text-md text-center mt-2">
            Check out {creatorHighlight.name}, our featured creator this week!
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default WeeklyReport;
